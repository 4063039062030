import React, { useEffect, useState } from "react";
import { TimezoneSelectorProps } from "./types";
import { Timezone } from "@/services/TimezonesService/types";
import { TimezonesService } from "@/utils/timezoneHelper";
import { SearchSelect } from "../SearchSelect";

const timezones = TimezonesService.timezones;

export function TimezoneSelector({ placeholder, label, value, onChange }: TimezoneSelectorProps) {
  const [selectedTimezone, setSelectedTimezone] = useState<Timezone>(null);
  const [query, setQuery] = useState<string>("");

  const filteredTimezones =
    query === ""
      ? timezones
      : timezones.filter((tz) => {
          return tz.timezone.toLowerCase().includes(query.toLowerCase());
        });

  useEffect(() => {
    if (value) {
      const currentTz = timezones.find((tz) => tz.timezone === value);
      if (currentTz) {
        setSelectedTimezone(currentTz);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedTimezone) {
      onChange(selectedTimezone.timezone);
    }
  }, [selectedTimezone]);

  return (
    <SearchSelect
      fluid
      onChange={setSelectedTimezone}>
      <SearchSelect.Input
        id="event-timezone-selector"
        dataQa="event-timezone-input"
        label={label}
        placeholder={placeholder}
        value={value}
        displayValue={selectedTimezone?.displayName}
      />
      <SearchSelect.Options
        dataQa="event-timezone-options"
        onSearch={(event) => setQuery(event.target.value)}>
        {filteredTimezones.map((tz) => (
          <SearchSelect.Option
            key={tz.timezone}
            value={tz}>
            {tz.displayName}
          </SearchSelect.Option>
        ))}
      </SearchSelect.Options>
    </SearchSelect>
  );
}
