import React from "react";
import classNames from "classnames";
import { TbAlertCircle } from "react-icons/tb";

import { AlertProps } from "./types";

import "./styles.scss";

export const Alert = ({ text, maxWidth, className, button, icon, type }: AlertProps) => {
  const classnames = classNames(`Alert`, {
    [`Alert--${className}`]: className,
    "Alert--warning": type === "warning",
    "Alert--info": type === "info",
    "Alert--success": type === "success",
    "Alert--error": type === "error",
    "Alert--default": type === "default",
  });

  if (icon === "warning") {
    icon = <TbAlertCircle aria-label="alert-icon" />;
  }

  return (
    <div
      data-testid="Alert"
      className={classnames}
      style={{ maxWidth: maxWidth || "100%" }}>
      <div className="Alert--left">
        {icon && icon}
        {text && <span className="Alert__text">{text}</span>}
      </div>
      {button && <div className="Alert--right">{button}</div>}
    </div>
  );
};
