import React from "react";
import { FiEdit2, FiClock } from "react-icons/fi";
import { Typography } from "@/components/Typography";
import { formatTimeToView } from "@/utils/timerHelper";
import { TimerViewProps } from "./types";

import "./styles.scss";

export const TimerView = (props: TimerViewProps) => {
  const {
    time,
    timeFormat,
    viewType,
    selectedTimeType,
    AMPMTimeFormat,
    onChangeViewType,
    onChangeHourMinutes,
    onChangeAMPMTimeFormat,
  } = props;

  const [persistedTime, setPersistedTime] = React.useState(time);
  const formattedTime = React.useMemo(() => {
    return formatTimeToView(time, persistedTime);
  }, [time]);

  const { hour, minutes } = formattedTime;

  React.useEffect(() => {
    setPersistedTime(time);
  }, []);

  React.useEffect(() => {
    setPersistedTime(formattedTime);
  }, [formattedTime]);

  return (
    <div className="timer__view">
      <div className="timer__view__container">
        <div className="timer__view__time">
          <Typography
            Tag="p"
            size="xl"
            classname={`timer__view__time__hour ${
              selectedTimeType === "hour" ? "timer__view__time__hour--selected" : ""
            }`}
            onClick={() => viewType === "analogical" && onChangeHourMinutes("hour")}>
            {hour || "00"}
          </Typography>
          <span className="timer__view__time__separator">:</span>
          <Typography
            Tag="p"
            size="xl"
            classname={`timer__view__time__minutes ${
              selectedTimeType === "minutes" ? "timer__view__time__minutes--selected" : ""
            }`}
            onClick={() => viewType === "analogical" && onChangeHourMinutes("minutes")}>
            {minutes || "00"}
          </Typography>
        </div>
        <div className="timer__view__time__button-container">
          {timeFormat === "AM/PM" && (
            <React.Fragment>
              <span
                className={`timer__view__time__button-am ${
                  AMPMTimeFormat === "AM" ? "timer__view__time__button-am--selected" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => onChangeAMPMTimeFormat("AM")}>
                AM
              </span>
              <span
                className={`timer__view__time__button-pm ${
                  AMPMTimeFormat === "PM" ? "timer__view__time__button-pm--selected" : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => onChangeAMPMTimeFormat("PM")}>
                PM
              </span>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="timer__view__time__edit-clock">
        {viewType === "analogical" ? (
          <FiEdit2
            className="timer__view__time__button-edit"
            onClick={() => onChangeViewType("digital")}
          />
        ) : (
          <FiClock
            className="timer__view__time__button-clock"
            onClick={() => onChangeViewType("analogical")}
          />
        )}
      </div>
    </div>
  );
};
