import React from "react";
import { SelectorInputProps } from "./types";
import { FiChevronDown } from "react-icons/fi";

export const SelectorInput = ({
  label,
  onClick,
  displayValue,
  placeholder,
  dataQa,
}: SelectorInputProps) => {
  const onClickInputHandler = (event) => {
    event.stopPropagation();
    onClick(event);
  };

  return (
    <>
      <label>{label}</label>
      <div
        className="search-select__input"
        onClick={onClick}>
        <input
          data-qa={dataQa}
          placeholder={placeholder}
          value={displayValue}
          className="search-select__value"
          readOnly
          onClick={onClickInputHandler}
        />
        <FiChevronDown className="search-select__icon" />
      </div>
    </>
  );
};
