import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ReactSVG } from "react-svg";
import { find, filter, cloneDeep, findIndex } from "lodash";
import { useTranslation } from "react-i18next";
import { Input } from "../Input";
import { Typography } from "../Typography";
import { Tooltip } from "../Tooltip";
import ListPickerItem from "./ListPickerItem";

import { ConnectForm, URLRegex } from "@/utils/formHelper";

import { ListPickerProps } from "./types";
import { FormValidationMethods } from "../Form/types";

import "./styles.scss";

export function ListPicker({
  dataQA,
  title,
  titleEmpty,
  defaultValue,
  options,
  onClick,
  extended,
}: ListPickerProps) {
  const methods: FormValidationMethods = useFormContext();
  const [selectedOptions, setSelectedOptions] = useState(defaultValue || []);
  const { t } = useTranslation();

  const addRemoveOption = (item) => {
    const itemInArray = find(selectedOptions, { id: item.id });
    if (itemInArray) {
      methods?.setValue(itemInArray.id, "");
      const filteredArray = filter(selectedOptions, (option) => option.id !== item.id);

      setSelectedOptions(filteredArray);
    } else {
      setSelectedOptions([...selectedOptions, item]);
    }
  };

  const onChangeValue = (item, inputType?: "text" | "url") => {
    const { id } = item;
    const newSelectedSocials = cloneDeep(selectedOptions);
    const targetSocial = findIndex(newSelectedSocials, { id });
    if (inputType) {
      const inputName = inputType === "url" ? id : `${id}-${inputType}`;
      newSelectedSocials[targetSocial][inputType].value = methods?.getValues(inputName);
    } else {
      newSelectedSocials[targetSocial].value = methods?.getValues(id);
    }

    onClick && setSelectedOptions(newSelectedSocials);
  };

  React.useEffect(() => {
    if (onClick) {
      onClick(selectedOptions);
    }
  }, [selectedOptions]);

  return (
    <ConnectForm>
      <div
        data-testid="ListPicker"
        data-qa={dataQA}
        className="ListPicker">
        <div>
          {selectedOptions.map((item) => {
            if (extended) {
              const { url, text } = item;
              return (
                <div
                  className={`ListPicker__social-wrapper`}
                  key={item.id}>
                  <div className={`ListPicker__social-name`}>
                    <ReactSVG
                      className="ListPicker__icon"
                      src={`/svg/icon-${item.id}.svg`}
                    />
                    <span className="ListPicker__label">{item.name}</span>
                  </div>
                  <div className={`ListPicker__input-wrapper--extended`}>
                    <div>
                      <span className="ListPicker__label">{url.label}</span>
                      <Controller
                        control={methods?.control}
                        name={item.id}
                        render={() => (
                          <Input
                            id={item.id}
                            type="text"
                            name={item.id}
                            defaultValue={find(defaultValue, { id: item.id })?.url?.value}
                            onChange={() => onChangeValue(item, "url")}
                            error={methods?.formState?.errors[item.id]}
                            errorMessage={methods?.formState?.errors[item.id]?.message}
                            noMb
                            placeholder={url.placeholder}
                          />
                        )}
                        rules={{
                          required: {
                            value: true,
                            message: t("_requiredMessage"),
                          },
                          pattern: {
                            value: new RegExp(URLRegex),
                            message: t("_socialInvalid"),
                          },
                        }}
                      />
                    </div>
                    <div>
                      <span className="ListPicker__label">{text.label}</span>
                      <Controller
                        control={methods?.control}
                        name={`${item.id}-text`}
                        render={() => (
                          <Input
                            id={`${item.id}-text`}
                            type="text"
                            name={`${item.id}-text`}
                            defaultValue={find(defaultValue, { id: item.id })?.text?.value}
                            onChange={() => onChangeValue(item, "text")}
                            error={methods?.formState?.errors[`${item.id}-text`]}
                            errorMessage={methods?.formState?.errors[`${item.id}-text`]?.message}
                            noMb
                            placeholder={text.placeholder}
                          />
                        )}
                        rules={{
                          maxLength: {
                            value: 70,
                            message: t("_socialMaxLength"),
                          },
                        }}
                      />
                    </div>
                  </div>
                  <ReactSVG
                    data-testid="ListPickerRemoveButton"
                    className="ListPicker__icon ListPicker__remove"
                    src={`/svg/icon-remove.svg`}
                    onClick={() => addRemoveOption(item)}
                  />
                  {/* <button onClick={() => addRemoveOption(item)}>remove</button> */}
                </div>
              );
            }
            return (
              <div
                className={`ListPicker__social-wrapper`}
                key={item.id}>
                <div className={`ListPicker__social-name`}>
                  <ReactSVG
                    className="ListPicker__icon"
                    src={`/svg/icon-${item.id}.svg`}
                  />
                  <span className="ListPicker__label">{item.name}</span>
                </div>
                <div className={`ListPicker__input-wrapper`}>
                  <Input
                    id={item.id}
                    type="text"
                    name={item.id}
                    defaultValue={find(defaultValue, { id: item.id })?.value}
                    onChange={() => onClick && onChangeValue(item)}
                    error={methods?.formState?.errors[item.id]}
                    errorMessage={methods?.formState?.errors[item.id]?.message}
                    noMb
                    placeholder={item.placeholder}
                  />
                </div>

                {/* TODO: Check ReactSVG re-rendering issue */}
                <div
                  data-testid="ListPickerRemoveButton"
                  className="ListPicker__icon ListPicker__remove"
                  onClick={() => addRemoveOption(item)}>
                  <div>
                    <span></span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {selectedOptions.length ? (
          <Typography
            classname="u-bold"
            Tag={"p"}>
            {titleEmpty}
          </Typography>
        ) : (
          <Typography
            classname="u-bold"
            Tag={"p"}>
            {title}
          </Typography>
        )}
        <ul
          data-testid="ListPicker__list"
          className="ListPicker__list">
          {options.map((item, index) => (
            <React.Fragment key={index}>
              {item.tooltip ? (
                <Tooltip
                  text={item.tooltip?.text}
                  position={item.tooltip?.position}
                  id={`${index}`}>
                  <ListPickerItem
                    item={item}
                    selectedOptions={selectedOptions}
                    addRemoveOption={addRemoveOption}
                    id={index}
                  />
                </Tooltip>
              ) : (
                <ListPickerItem
                  item={item}
                  selectedOptions={selectedOptions}
                  addRemoveOption={addRemoveOption}
                  id={index}
                />
              )}
            </React.Fragment>
          ))}
        </ul>
      </div>
    </ConnectForm>
  );
}
