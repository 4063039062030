import { TimePicker } from "@/components/TimePicker";
import { TimerModalProps } from "@/components/TimePicker/types";
import { ModalProps } from "@/contexts/ModalContext/types";
import React from "react";

export const getTimerModal = (props: ModalProps<TimerModalProps>) => {
  const { data, onConfirmButton, onCancelButton } = props;

  const { timeFormat, hour, minutes, type } = data;

  return (
    <React.Fragment>
      <TimePicker
        label="SELECT TIME"
        viewType="analogical"
        timeFormat={timeFormat || "24"}
        time={{
          hour: hour || "08",
          minutes: minutes || "00",
          type: type || "AM",
        }}
        onChangeTime={(time) => onConfirmButton(time)}
        onCancelChange={() => onCancelButton()}
      />
    </React.Fragment>
  );
};
