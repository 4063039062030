import React from "react";
import classNames from "classnames";
import { FiAlertCircle, FiChevronDown } from "react-icons/fi";

import { SelectProps } from "./types";

import "./styles.scss";
import { ConnectForm } from "@/utils/formHelper";
import { InputTooltipHelper } from "@/utils/inputTooltipHelper";

export const Select = ({
  id,
  dataQA,
  label,
  options,
  placeholder,
  errorMessage,
  error,
  success,
  disabled,
  defaultValue,
  name,
  helper,
  className,
  prefix,
  onChange,
}: SelectProps) => {
  const classnames = classNames(`Select`, {
    [className]: className,
    "Select--error": error,
    "Select--success": success,
    "Select--disabled": disabled,
  });

  const [value, setValue] = React.useState(defaultValue);
  const [selectoptions] = React.useState([placeholder, ...options]);
  const [prefixWidth, setPrefixWidth] = React.useState(0);

  const prefixRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(e.target?.value);
      setValue(e.target?.value);
    }
  };

  React.useEffect(() => {
    if (prefixRef.current) {
      setPrefixWidth(prefixRef.current.getBoundingClientRect().width);
    }
  }, [prefix]);

  const tooltipHelper = helper && typeof helper !== "string";

  return (
    <div
      data-testid="Select"
      data-qa={dataQA}
      className={classnames}>
      <div className="Input__label-container">
        {label && (
          <label
            data-testid="Select__label"
            htmlFor={id}
            className="Select__label">
            {label}
          </label>
        )}
        {tooltipHelper && InputTooltipHelper({ helper, id })}
      </div>

      <div className="Select__wrapper">
        {prefix && (
          <div
            ref={prefixRef}
            className="Select__prefix">
            {prefix}
          </div>
        )}
        <ConnectForm>
          <select
            data-testid="Select__element"
            id={id}
            disabled={disabled}
            name={name}
            value={value}
            required
            //placeholder={placeholder}
            onChange={handleOnChange}
            style={{ paddingLeft: prefixWidth ? `${prefixWidth + 5}px` : "" }}>
            {selectoptions?.map((item, id) => (
              <option
                data-testid="Select__option"
                className={placeholder && id === 0 ? "Select__element--placeholder" : ""}
                key={id}
                value={id === 0 ? "" : item}
                data-placeholder={!!(id === 0)}>
                {item}
              </option>
            ))}
          </select>
        </ConnectForm>
        <FiChevronDown />
      </div>
      {error && (
        <p
          data-testid="Select__error-message"
          className="Select__error-message body-s">
          <FiAlertCircle />
          {errorMessage}
        </p>
      )}
      {helper && !tooltipHelper && <span className="Select__helper">{helper}</span>}
    </div>
  );
};
