import React from "react";
import { FiCalendar } from "react-icons/fi";
import { DatePickerProps, DatePickerSharedProps } from "./types";

import "./styles.scss";
import classNames from "classnames";
import { Typography } from "../Typography";
import { CalendarCustomInputProps } from "../Calendar/types";
import { Calendar } from "../Calendar";

const CustomInput = (props: CalendarCustomInputProps & DatePickerSharedProps) => {
  const { innerRef, icon, label, inputPlaceholder } = props;
  return (
    <div>
      {label && <Typography Tag="span">{label}</Typography>}
      <div className="date-picker__input">
        <input
          type="text"
          className="date-picker__input__box"
          placeholder={inputPlaceholder || "Select Date"}
          ref={innerRef}
        />
        <div className="date-picker__input__icon">{icon || <FiCalendar />}</div>
      </div>
    </div>
  );
};

export function DatePicker(props: DatePickerProps) {
  const {
    dataQA,
    label,
    icon,
    type,
    defaultDate,
    inputPlaceholder,
    minDate,
    maxDate,
    fluid,
    className,
    onChangeDate,
    disableMobile,
  } = props;

  const classes = classNames("date-picker", {
    "date-picker--fluid": fluid,
    [className]: className,
  });

  return (
    <div
      className={classes}
      data-testid="date-picker"
      data-qa={dataQA}>
      <Calendar
        type={type}
        maxDate={maxDate}
        minDate={minDate}
        defaultDate={defaultDate}
        onChangeDate={onChangeDate}
        disableMobile={disableMobile}
        customInput={(props) => (
          <CustomInput
            {...props}
            label={label}
            icon={icon}
            inputPlaceholder={inputPlaceholder}
          />
        )}
      />
    </div>
  );
}
