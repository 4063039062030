import React from "react";
import { FiChevronDown } from "react-icons/fi";
import classNames from "classnames";
import { ModalContext } from "@/contexts/ModalContext";
import { getTimerModal } from "@/modals/TimerModal";

import {
  formatToBusinessHoursInput,
  formatToGenericTime,
  formatToTimerTime,
} from "@/utils/timerHelper";
import { HoursSelectorProps } from "./types";

import "./styles.scss";

export function HoursSelector({
  value,
  name,
  placeholder,
  disabled,
  timeFormat,
  onSelectHour,
}: HoursSelectorProps) {
  const modalContext = React.useContext(ModalContext);

  const onSelectTime = (value: string, name) => {
    const formattedTimerTime = formatToTimerTime(value || "08:00", timeFormat);

    const { hour, minutes, type } = formattedTimerTime;

    modalContext.handleModal({
      content: getTimerModal({
        onConfirmButton: (time) => {
          const formattedTime = formatToGenericTime(time);
          onSelectHour && onSelectHour({ name, value: formattedTime });
          modalContext.handleModal();
        },
        onCancelButton: () => modalContext.handleModal(),
        data: {
          timeFormat,
          hour,
          minutes,
          type,
        },
      }),
    });
  };

  const onSelectClickHandler = () => onSelectTime(value, name);

  const classes = {
    main: classNames("hours-selector", {
      "hours-selector--disabled": disabled,
    }),
    content: classNames("", {
      "hours-selector__default": !!value,
      "hours-selector__placeholder": !value,
    }),
  };

  const formattedTime = value && formatToBusinessHoursInput(value, timeFormat);
  const content = formattedTime ?? placeholder;

  return (
    <div
      onClick={onSelectClickHandler}
      className={classes.main}>
      <span className={classes.content}>{content}</span>
      <FiChevronDown />
    </div>
  );
}
