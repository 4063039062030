import React from "react";

import { AnalogicalTimer } from "@/components/AnalogicalTimer";
import { ITimeTimerInput } from "@/components/TimerInput/types";

import { buildTime, formatNumberTimeToString, formatStringTimeToNumber } from "@/utils/timerHelper";

import { AnalogicalTimerSelectorProps, TimeType } from "./types";

const formatHourMinutes = (originalTime: ITimeTimerInput, hour: number, timeType?: TimeType) => {
  const stringifiedHour = formatNumberTimeToString(hour);
  const formattedTime = buildTime(originalTime, timeType || "hour", stringifiedHour);

  return formattedTime;
};

export const AnalogicalTimerSelector = (props: AnalogicalTimerSelectorProps) => {
  const { timeFormat = "hour", type, time, onTimeChange, onChangeTimeType } = props;

  const { hour, minutes } = time;

  return (
    <div
      style={{
        width: "220px",
        height: "220px",
        display: "flex",
        justifyContent: "center",
      }}>
      {type === "minutes" ? (
        <AnalogicalTimer
          type="minutes"
          duration={formatStringTimeToNumber(minutes)}
          onDurationChange={(minutes) =>
            onTimeChange(formatHourMinutes(time, minutes, "minutes"), "minutes")
          }
        />
      ) : timeFormat === "hour" ? (
        <AnalogicalTimer
          type="hour"
          duration={formatStringTimeToNumber(hour)}
          onDurationChange={(hour) => onTimeChange(formatHourMinutes(time, hour), "hour")}
          onChangeTimeType={(timeType) => onChangeTimeType(timeType)}
        />
      ) : (
        <AnalogicalTimer
          type="hour24"
          duration={formatStringTimeToNumber(hour)}
          onDurationChange={(hour24) => onTimeChange(formatHourMinutes(time, hour24), "hour")}
          onChangeTimeType={(timeType) => onChangeTimeType(timeType)}
        />
      )}
    </div>
  );
};
