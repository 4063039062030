"use client";
import React, { useContext } from "react";

import { AlertContentProps } from "./types";
import { UserContextProps } from "@/contexts/UserContext/types";
import { SubscriptionStatus } from "@/types/subscription";
import { Button } from "../Button";
import { Alert } from "./Alert";
import { Chip } from "../Chip";
import { AmplitudeContext } from "@/contexts/AmplitudeContext/AmplitudeContext";
import { Link } from "../Link";
import { isUserFreeTrial } from "@/services/UserService/UserService";

const isAccountValidatedAndActived = (userContext: UserContextProps, isUserValidated: boolean) => {
  let isActive = false;

  if (
    !userContext?.user?.email ||
    !isUserValidated ||
    userContext?.user?.subscription?.status === SubscriptionStatus.ACTIVE
  ) {
    isActive = true;
  }

  return isActive;
};

export const AIDashboardAlert = ({ isUserValidated, userContext, content }: AlertContentProps) => {
  const { setSource } = useContext(AmplitudeContext);
  if (isAccountValidatedAndActived(userContext, isUserValidated)) return;
  if (isUserFreeTrial(userContext)) return;

  const alertContent = (
    <div className="Dashboard__header__AI__alert">
      <Chip
        label={content.chipLabel}
        type="filled"
        color="warning"
      />
      <Link
        href={content.PricingLink}
        className="bold-text button button--large button--ghost"
        type="button"
        onClick={() => setSource("banner_ia_myaccount")}
        id="pricing-link">
        {content.AIAlertLinkLabel}
      </Link>
    </div>
  );

  return (
    <Alert
      text={alertContent}
      className="centered"
      type="default"
    />
  );
};
