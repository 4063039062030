"use client";
import { FormEvent, useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormFields } from "./FormFields";
import { GlobalContext } from "@/contexts/GlobalContext";
import { FormProps, FormValidationMethods } from "./types";

import "./styles.scss";

export const Form = (props: FormProps) => {
  const {
    fields,
    schema,
    isLoading,
    isDisabled,
    className,
    isAuth,
    isSubmitDisabled,
    onInit,
    isFormValid,
    onSubmit,
    onWatchFields,
    validationMode = "onBlur",
  } = props;

  const { context, setContext } = useContext(GlobalContext);
  const useFormProps = useForm({
    mode: validationMode,
    reValidateMode: validationMode,
  });

  const methods: FormValidationMethods = {
    ...useFormProps,
    schema,
  };

  const onChangeFields = (event: any) => {
    if (!onWatchFields) return undefined;
    if (onWatchFields) {
      onWatchFields({
        field: event.target.name,
        value: event.target.value,
        component: event.target,
        methods: methods,
      });
    }
  };

  useEffect(() => {
    setContext({ ...context, formMethods: methods });
    onInit && onInit(useFormProps);
  }, []);

  useEffect(() => {
    if (schema && !context.formMethods) {
      setContext({ ...context, formMethods: methods });
    }
  }, [schema]);

  useEffect(() => {
    setTimeout(() => {
      isFormValid && isFormValid(methods.formState.isValid);
    }, 800);
  }, [methods.formState.isValid]);

  return (
    <FormProvider {...methods}>
      <form
        className={`Form ${className ? className : ""}`}
        data-testid="Form"
        onSubmit={
          !isSubmitDisabled &&
          methods.handleSubmit(async () => {
            await onSubmit(methods.getValues(), methods);
          })
        }
        onChange={(event: FormEvent<HTMLFormElement>) => onChangeFields(event)}
        noValidate
        id="Form">
        <FormFields
          fields={fields}
          methods={methods}
          isLoading={isLoading}
          isDisabled={isDisabled}
          isAuth={isAuth}
        />
      </form>
    </FormProvider>
  );
};
